import { Link } from 'react-router-dom';
import style from './style.module.scss';

const Button = ({ className, title, href, to, onClick, sm, border, blue }) => {
  if (href) {
    return (
      <a
        href={href}
        target='_blank'
        rel='noreferrer'
        className={`${style.button} 
        ${sm && style.sm}
        ${blue && style.blue}
         ${border && style.border} ${className}`}
      >
        {title}
      </a>
    );
  } else if (to) {
    return (
      <Link
        to={to}
        className={`${style.button} ${sm && style.sm} ${blue && style.blue} ${
          border && style.border
        } ${className}`}
      >
        {title}
      </Link>
    );
  } else {
    return (
      <button
        onClick={onClick}
        className={`${style.button} ${sm && style.sm} ${blue && style.blue} ${
          border && style.border
        } ${className}`}
      >
        {title}
      </button>
    );
  }
};

export default Button;
