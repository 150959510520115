import { useMediaQuery } from 'usehooks-ts';
import style from './styles.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LinksArray } from './Links';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  const navTo = (link) => {
    setBurgerOpen(false);
    navigate(link);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);
  };

  const linkList = () => {
    return LinksArray.map((item, index) => (
      <li
        onClick={() => navTo(item.to)}
        key={index}
        className={`${style.headerBody__linksItem} ${
          item.to === location.pathname.split('/')[1] && style.active
        }`}
      >
        {item.name}
      </li>
    ));
  };

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      if (moving > 100) {
        setVisible(position > moving);
        setPosition(moving);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      <header
        ref={headerRef}
        className={`${style.header} ${!visible && style.hidden} 
        ${
          location.pathname.split('/')[1] === 'crypto-fixed-income' &&
          style.yellow
        }
        `}
      >
        <div className='__container'>
          <div className={style.headerBody}>
            <Link to={'asset-management'} className={style.headerBody__logo}>
              AlgoQuant
            </Link>
            {!isMobile && (
              <nav className={style.headerBody__links}>
                <ul>{linkList()}</ul>
              </nav>
            )}
            {isMobile && (
              <div
                className={style.headerBody__burger}
                onClick={() => setBurgerOpen(true)}
              >
                <span></span>
                <span></span>
              </div>
            )}
          </div>
        </div>
      </header>
      {isMobile && (
        <div
          className={`${style.menu} ${isBurgerOpen && style.open} ${
            location.pathname.split('/')[1] === 'crypto-fixed-income' &&
            style.yellow
          }`}
        >
          <div className='__container'>
            <div className={style.menuBody}>
              <div className={style.menuTop}>
                <div className={style.menuTop__title}>AlgoQuant</div>
                <div
                  className={style.menuTop__close}
                  onClick={() => setBurgerOpen(false)}
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
              <nav className={style.menuLinks}>
                <ul>{linkList()}</ul>
              </nav>
              <div className={style.menuBottom}>
                <div className={style.menuBottom__logo}>menu</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
