import Text from '../../../../components/Text';
import Title from '../../../../components/TItle';
import style from './style.module.scss';

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import { Fade } from 'react-reveal';

const PartnershipSection = () => {
  return (
    <section className={style.partnership}>
      <div className='__container'>
        <Title h2 black className={style.partnership__title}>
          <Fade>
            <>
              <div>Trusted</div>
              <br />
              <div>
                <i></i> Partnerships
              </div>
            </>
          </Fade>
        </Title>
        <Fade>
          <Title h3 grey className={style.partnership__text}>
            Collaborations with prime brokers, exchanges, custodians, legal,
            auditors, insurance, compliance, and IT/cybersecurity providers
          </Title>
        </Fade>
        <Fade>
          <div className={style.partnershipBody}>
            <a
              href='https://kpmg.com/xx/en/home.html'
              target='_blank'
              rel='noreferrer'
              className={style.partnershipBody__card}
            >
              <img src={icon1} alt='' />
            </a>
            <a
              href='https://www.ogier.com/'
              target='_blank'
              rel='noreferrer'
              className={style.partnershipBody__card}
            >
              <img src={icon2} alt='' />
            </a>
            <a
              href='https://www.navconsulting.net/'
              target='_blank'
              rel='noreferrer'
              className={style.partnershipBody__card}
            >
              <img src={icon3} alt='' />
            </a>
            <a
              href='https://perkinscoie.com/'
              target='_blank'
              rel='noreferrer'
              className={style.partnershipBody__card}
            >
              <img src={icon4} alt='' />
            </a>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default PartnershipSection;
