import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/PageHeading';
import style from './Managers.module.scss';
import bgImg from './assets/bg.jpg';
import WhySection from './Modules/Why';
import OfferingSection from './Modules/Offering';
import EdgeSection from './Modules/Edge';
import PartnershipSection from './Modules/Partnership';
import TeamSection from './Modules/Team';

// const BenefitsSection = React.lazy(() => import('./Modules/Benefits'));
// const AboutSection = React.lazy(() => import('./Modules/About'));
// const CtaSection = React.lazy(() => import('../../components/Cta'));

const ManagementPage = () => {
  return (
    <>
      <PageHeading
        img={bgImg}
        title={
          <>
            <div>AlgoQuant</div>
            <br />
            <div>
              <i></i> Asset Management
            </div>
          </>
        }
        text={
          <>
            Providing institutional investors with transparent and reliable
            smart products for earning money in the cryptocurrency market
          </>
        }
      />
      <WhySection
        title={'Why AlgoQuant'}
        text={`Digital markets offer unique investment opportunities due to market inefficiencies, 24/7 trading, a global investor base, high volatility and a wide range of investable assets. We have established a team with the skills and experience to take advantage of these opportunities and invested in the technical and operational infrastructure required for this evolving market. We have developed our trading strategies over the last five years and are now offering the opportunity to invest in products driven by these strategies.`}
      />
      <OfferingSection
        title={
          'An Open-ended Cayman Fund with one year lockup and quarterly liquidity'
        }
        list={[
          {
            title: 'Fund Type',
            text: 'Open-ended Cayman Fund',
          },
          {
            title: 'Investment Strategy',
            text: 'Advanced quantitative algorithms for optimized trading',
          },
          {
            title: 'Terms',
            text: 'Please enquire for details',
          },
          {
            title: 'Liquidity',
            text: 'One year lockup, quarterly liquidity',
          },
        ]}
      />
      <EdgeSection />
      <PartnershipSection />
      <TeamSection />
    </>
  );
};

export default ManagementPage;
