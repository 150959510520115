import style from './style.module.scss';

const Title = ({
  children,
  h1,
  h2,
  h3,
  blue,
  black,
  grey,
  yellow,
  className,
}) => {
  return (
    <div
      className={`${style.title} 
  ${h1 && style.h1}
  ${h2 && style.h2}
  ${h3 && style.h3}
  ${blue && style.blue}
  ${black && style.black}
  ${grey && style.grey}
  ${yellow && style.yellow}
  ${className}
  `}
    >
      {children}
    </div>
  );
};

export default Title;
