export const LinksArray = [
  {
    name: 'Prop Trading',
    to: '/',
  },
  {
    name: 'Asset Management',
    to: 'asset-management',
  },
  {
    name: 'Crypto Fixed Income',
    to: 'crypto-fixed-income',
  },
  {
    name: 'Careers',
    to: '/',
  },
  {
    name: 'Contacts',
    to: '/',
  },
];
