import { Fade } from 'react-reveal';
import Title from '../../../../components/TItle';
import style from './style.module.scss';

const WhySection = ({ title, text, yellow }) => {
  return (
    <section className={`${style.why} ${yellow && style.yellow}`}>
      <div className='__container'>
        <div className={style.whyBody}>
          <Fade>
            <Title h3 blue yellow={yellow} className={style.whyBody__title}>
              {title}
            </Title>
          </Fade>
          <Title h3 black className={style.whyBody__text}>
            <Fade>{text}</Fade>
          </Title>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
