import { Fade } from 'react-reveal';
import Text from '../../../../components/Text';
import Title from '../../../../components/TItle';
import style from './style.module.scss';

const OfferingSection = ({ title, list, yellow }) => {
  return (
    <section className={style.offering}>
      <div className='__container'>
        <div className={style.offeringBody}>
          <Fade>
            <Title
              h3
              blue
              yellow={yellow}
              className={style.offeringBody__label}
            >
              Offering
            </Title>
          </Fade>
          <Fade>
            <Title h3 black className={style.offeringBody__title}>
              {title}
            </Title>
          </Fade>

          <ul className={style.offeringBody__list}>
            {list.map((item, index) => (
              <Fade up>
                <li key={index}>
                  <Text blue semiBold className={style.offeringBody__listTitle}>
                    {item.title}
                  </Text>
                  <Text>{item.text}</Text>
                </li>
              </Fade>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default OfferingSection;
