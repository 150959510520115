import Text from '../../../../components/Text';
import Title from '../../../../components/TItle';
import style from './style.module.scss';
import img from './assets/img.jpg';
import imgMob from './assets/img-mob.jpg';
import imgYellow from './assets/img-yellow.jpg';
import imgYellowMob from './assets/img-yellow-mob.jpg';
import { useMediaQuery } from 'usehooks-ts';
import { Fade } from 'react-reveal';

const EdgeSection = ({ yellow }) => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <section className={`${style.edge} ${yellow && style.yellow}`}>
      <div className='__container'>
        <Title h2 className={style.edge__title}>
          <Fade>The AlgoQuant Edge</Fade>
        </Title>
        <div className={style.edgeBody}>
          <div className={style.edgeBody__row}>
            <Card
              title={'Alpha-Generating Strategies'}
              text={'Proven over five years'}
              num={'01'}
              yellow={yellow}
            />
          </div>
          <div className={style.edgeBody__row}>
            <Card
              title={'Regulated Investment Vehicles'}
              text={'Ensuring compliance and security'}
              num={'02'}
              yellow={yellow}
            />
            <Card
              title={
                <>
                  Innovative <br /> Products
                </>
              }
              text={'Tailored for institutional investors'}
              num={'03'}
              yellow={yellow}
            />
          </div>
          <div className={style.edgeBody__row}>
            <Card
              title={'Institutional Quality Processes'}
              text={'Top-tier operational excellence'}
              num={'04'}
              yellow={yellow}
            />
            <Card
              title={'Industry Recognition'}
              text={'Member of AIMA and other reputable organizations'}
              num={'05'}
              yellow={yellow}
            />
          </div>

          {!isMobile && (
            <div className={style.edgeBody__img}>
              <img src={yellow ? imgYellow : img} alt='' />
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={style.edge__img}>
          <img src={yellow ? imgYellowMob : imgMob} alt='' />
        </div>
      )}
    </section>
  );
};

const Card = ({ title, text, num, yellow }) => {
  return (
    <div className={`${style.card} ${yellow && style.yellow}`}>
      <div className={style.cardContent}>
        <Title h3 className={style.cardContent__title}>
          <Fade>{title}</Fade>
        </Title>
        <Text className={style.cardContent__text}>
          <Fade>{text}</Fade>
        </Text>
      </div>
      <Text white className={style.card__num}>
        <Fade>{num}</Fade>
      </Text>
    </div>
  );
};

export default EdgeSection;
