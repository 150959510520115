import style from './style.module.scss';

const Text = ({
  children,
  blue,
  sm,
  black,
  grey,
  white,
  className,
  semiBold,
}) => {
  return (
    <div
      className={`${style.text} 
  ${blue && style.blue}
  ${black && style.black}
  ${grey && style.grey}
  ${sm && style.sm}
  ${white && style.white}
  ${semiBold && style.semiBold}
  ${className}
  `}
    >
      {children}
    </div>
  );
};

export default Text;
