import style from './style.module.scss';
import Title from '../TItle';
import Text from '../Text';
import Button from '../Button';
import { LinksArray } from '../Header/Links';
import { Link, useLocation } from 'react-router-dom';

import bgAssetManagement from './assets/bg-asset-management.jpg';
import bgCryproFixedIncome from './assets/bg-crypto-fixed-income.jpg';
import { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';

const Footer = () => {
  const location = useLocation();
  const [img, setImg] = useState(bgAssetManagement);

  const currentImg = () => {
    switch (location.pathname.split('/')[1]) {
      case 'asset-management':
        setImg(bgAssetManagement);
        break;
      case 'crypto-fixed-income':
        setImg(bgCryproFixedIncome);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    currentImg();
  }, [location]);

  return (
    <footer className={style.footer}>
      <div className={style.footer__bg}>
        <img src={img} alt='' />
      </div>
      <div className='__container'>
        <div className={style.footerTop}>
          <Fade>
            <Title h2 className={style.footer__title}>
              <div>Unlock new investment</div>
              <br />
              <div>opportunities</div>
              <br />
              <div>
                <i></i> <span>With AlgoQuant</span>
              </div>
            </Title>
          </Fade>
          <Fade>
            <Button
              blue
              href={'mailto:info@algoquant.io'}
              className={style.footer__button}
              title={'Contact with us'}
            />
          </Fade>
        </div>
        <div className={style.footerBody}>
          <div className={style.footerCol}>
            <Text white>AlgoQuant</Text>
            <Text className={style.footerBody__text} blue sm>
              This web-based presentation of AlgoQuant is for information only
              and shall not constitute an offer to buy, sell, issue or subscribe
              for, or the solicitation of an offer to buy, sell or issue, or
              subscribe for any securities. 
            </Text>
          </div>
          <div className={`${style.footerCol} ${style.row}`}>
            <div className={style.footerBody__contact}>
              <Fade>
                <Text white>Contact Us</Text>
              </Fade>
              <Fade>
                <a href='mailto:info@algoquant.io'>
                  <Text blue>info@algoquant.io</Text>
                </a>
              </Fade>
              <div className={style.footerBody__contactSocial}>
                <Fade>
                  <a
                    href='https://www.linkedin.com/company/algo-quant/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      width='29'
                      height='29'
                      viewBox='0 0 29 29'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.7068 24.7128H20.4124V17.9836C20.4124 16.379 20.3798 14.3139 18.1745 14.3139C15.9355 14.3139 15.5935 16.06 15.5935 17.8652V24.7128H11.2991V10.875H15.4244V12.7612H15.48C16.0563 11.6737 17.458 10.5258 19.552 10.5258C23.9033 10.5258 24.708 13.3895 24.708 17.1173L24.7068 24.7128ZM6.44888 8.98154C5.06654 8.98154 3.95608 7.86263 3.95608 6.48633C3.95608 5.11125 5.06775 3.99354 6.44888 3.99354C7.82638 3.99354 8.94288 5.11125 8.94288 6.48633C8.94288 7.86263 7.82517 8.98154 6.44888 8.98154ZM8.60213 24.7128H4.29563V10.875H8.60213V24.7128ZM26.8552 0H2.13996C0.957 0 0 0.93525 0 2.08921V26.9108C0 28.066 0.957 29 2.13996 29H26.8516C28.0333 29 29 28.066 29 26.9108V2.08921C29 0.93525 28.0333 0 26.8516 0H26.8552Z'
                        fill='#6F8796'
                      />
                    </svg>
                  </a>
                </Fade>
              </div>
            </div>
            <div className={style.footerBody__links}>
              {LinksArray.map((item, index) => (
                <Fade up>
                  <Link key={index} to={item.to}>
                    <Text white>{item.name}</Text>
                  </Link>
                </Fade>
              ))}
            </div>
          </div>
        </div>
        <div className={style.footerBottom}>
          <div className={style.footerCol}>
            <Fade>
              <a href='' className={style.policy}>
                <Text sm>Privacy and Cookies Policy</Text>
              </a>
            </Fade>
          </div>
          <div className={style.footerCol}>
            <Fade>
              <Text sm>Copyright 2024 AlgoQuant</Text>
            </Fade>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
