import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/PageHeading';
import style from './Managers.module.scss';
import bgImg from './assets/bg.jpg';
import { useMediaQuery } from 'usehooks-ts';
import TeamSection from '../Management/Modules/Team';
import PartnershipSection from '../Management/Modules/Partnership';
import EdgeSection from '../Management/Modules/Edge';
import OfferingSection from '../Management/Modules/Offering';
import WhySection from '../Management/Modules/Why';

// const BenefitsSection = React.lazy(() => import('./Modules/Benefits'));
// const AboutSection = React.lazy(() => import('./Modules/About'));
// const CtaSection = React.lazy(() => import('../../components/Cta'));

const CryptoFixedIncomePage = () => {
  return (
    <>
      <PageHeading
        img={bgImg}
        title={
          <>
            <div>Crypto </div>
            <br />
            <div>
              <i></i> Fixed Income
            </div>
          </>
        }
        text={
          <>
            Bitcoin Bond Backed by Innovative <br /> Quantitative Trading
            Strategies
          </>
        }
      />
      <WhySection
        yellow
        title={'Why AlgoQuant'}
        text={`Digital markets offer unique investment opportunities due to market inefficiencies, 24/7 trading, a global investor base, high volatility and a wide range of investable assets. We have established a team with the skills and experience to take advantage of these opportunities and invested in the technical and operational infrastructure required for this evolving market. We have developed our trading strategies over the last five years and are now offering the opportunity to invest in products driven by these strategies.`}
      />
      <OfferingSection
        yellow
        title={
          'A Bitcoin denominated bond with a competitive yield of and capital protection through overcollateralization'
        }
        list={[
          {
            title: 'Issuer',
            text: 'AlgoQuant, a leader in algorithmic portfolio management',
          },
          {
            title: 'Bond Type',
            text: 'Bitcoin-backed fixed income security with capital protection',
          },
          {
            title: 'Investment Strategy',
            text: 'Advanced quantitative algorithms for optimized trading',
          },
          {
            title: 'Maturity',
            text: '3 years',
          },
          {
            title: 'Interest Rate',
            text: 'Competitive fixed interest rate, paid semi-annually',
          },
          {
            title: 'Issuance',
            text: 'Via Republic, the bonds will be issued digitally on-chain with Reg S and Reg D exemptions',
          },
          {
            title: 'Listing',
            text: 'INX and Assetera digitally on-chain',
          },
        ]}
      />
      <EdgeSection yellow />
      <PartnershipSection />
      <TeamSection />
    </>
  );
};

export default CryptoFixedIncomePage;
