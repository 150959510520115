import { useMediaQuery } from 'usehooks-ts';
import Button from '../Button';
import style from './style.module.scss';
import Title from '../TItle';
import { Fade } from 'react-reveal';

const PageHeading = ({ title, text, img }) => {
  const isMobile = useMediaQuery('(max-width:991px)');

  const scrollBottom = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: window.innerHeight,
    });
  };

  return (
    <main className={style.heading}>
      <div className={style.heading__bg}>
        <img src={img} alt='' />
      </div>
      <div className='__container'>
        <div className={style.headingBody}>
          {/* <div className={style.headingBody__title}>{title}</div> */}
          <Fade>
            <Title h1 className={style.headingBody__title}>
              {title}
            </Title>
          </Fade>
          <div className={style.headingBody__bottom}>
            <Fade>
              <Title h3 className={style.headingBody__bottomText}>
                {text}
              </Title>
            </Fade>
            {!isMobile && (
              <Fade>
                <Button
                  onClick={scrollBottom}
                  className={style.headingBody__bottomBtn}
                  title='show more'
                />
              </Fade>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageHeading;
