import Text from '../../../../components/Text';
import Title from '../../../../components/TItle';
import style from './Team.module.scss';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'usehooks-ts';
import { Fade } from 'react-reveal';

const TeamSection = () => {
  return (
    <section className={style.team}>
      <div className='__container'>
        <Title h2 black className={style.team__title}>
          <Fade>Leadership Team</Fade>
        </Title>
        <div className={style.teamBody}>
          <Card
            img={img1}
            name={'Michael Ashby'}
            pos={'CEO & CIO'}
            exp={'15+ years'}
            text={"Buy-side, member of AIMA's Digital Asset Working Group"}
            link={'https://www.linkedin.com/in/mpashby/'}
          />
          <Card
            img={img2}
            name={'David Drinkwater'}
            pos={'Chief Administrative Officer'}
            exp={'25+ years'}
            text={'Buy-side and sell-side '}
            link={'https://www.linkedin.com/in/davidndrinkwater/'}
          />
          <Card
            img={img3}
            name={'Alex Goncharov'}
            pos={'Founder & Head of Quantitative Research'}
            exp={'5+ years'}
            text={'Quant trading, founder of AlgoQuant'}
            link={'https://www.linkedin.com/in/alexander-goncharov-946597290/'}
          />
          <Card
            img={img4}
            name={'Uri Litvak'}
            pos={'Chief Legal Counsel'}
            exp={'27+ years'}
            text={'M&A, Corporate, Transactional and Securities'}
            link={'https://www.linkedin.com/in/uri-litvak-349963138/'}
          />
        </div>
      </div>
    </section>
  );
};

const Card = ({ img, name, pos, exp, text, link }) => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <Fade up>
      <div className={style.card}>
        <div className={style.card__img}>
          <img src={img} alt='' />
        </div>
        <div className={style.cardCol}>
          <Title h3 yellow className={style.cardCol__title}>
            {name}
          </Title>
          <Text className={style.cardCol__text}>{pos}</Text>
          {!isMobile && (
            <Button
              sm
              border
              className={style.cardCol__link}
              href={link}
              title={'LinkedIn'}
            />
          )}
        </div>
        <div className={style.cardCol}>
          <Title h3 blue className={style.cardCol__title}>
            {exp}
          </Title>
          <Text className={style.cardCol__text}>{text}</Text>
        </div>
        {isMobile && (
          <Button
            sm
            border
            className={style.cardCol__link}
            href={link}
            title={'LinkedIn'}
          />
        )}
      </div>
    </Fade>
  );
};

export default TeamSection;
